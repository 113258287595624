#g-recaptcha {
    transform: scale(0.85);
    transform-origin: 0 0;
}

// @media (max-width: 1200px) {
//     #g-recaptcha {
//         transform: scale(0.65);
//         transform-origin: 0 0;
//     }
// }

// @media (max-width: 990px) {
//     #g-recaptcha {
//         transform: scale(0.5);
//         transform-origin: 0 0;
//     }
// }

// @media (max-width: 580px) {
//     #g-recaptcha {
//         transform: scale(0.45);
//         transform-origin: 0 0;
//     }
// }