@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Thin.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-ThinItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-ExtraLight.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-ExtraLightItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-LightItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-MediumItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-SemiBoldItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-BoldItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-ExtraBold.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-ExtraBoldItalic.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-Black.otf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    src: local('Montserrat'), url(../fonts/Montserrat/Montserrat-BlackItalic.otf) format('opentype');
}